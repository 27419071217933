import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useStores } from "../../stores/RootStore";
import Button from "../button";
import RangeSlider from "../range-slider";
import styles from "./index.module.scss";

const PurchaseNfts = observer(() => {
	const { t } = useTranslation();
	const { uiStore, etherStore } = useStores();
	const { setShowMyNfts, isCorrectWallet } = uiStore;
	const { connectToWallet, nftBalance, purchaseNft, walletAddress, sliderValue, walletBalance, soldNfts, maxNFTendos } = etherStore;

	return (
		<div id="purchase" className={styles["component-container"]}>
			<div data-aos="zoom-in" className={styles["purchase-nft_container"]}>
				<h2>{t("purchaseNfts.title")}</h2>
				<div className={styles["purchase-nft_purchase"]}>
					<div className={styles["purchase-nft_image-container"]}>
						<img className={styles["purchase-nft_image"]} alt={"Kites of Freedom Minting"} src={"images/legendaries.gif"} />
					</div>
					<div className={styles["purchase-nft_purchase-container"]}>
						<div className={styles["purchase-nft_sold-title"]}>{soldNfts} / {maxNFTendos} {t("purchaseNfts.sold")}</div>
						{!walletAddress ?
							<></>
							:
							<div className={styles["purchase-nft_balance"]}>{t("purchase_nfts.balance")}
								<NumberFormat
									value={parseFloat(walletBalance).toFixed(4)}
									displayType={'text'}
									thousandSeparator={true} />
								{' MOVR'}
							</div>
						}
						<RangeSlider />
						{!walletAddress ?
							//<Button label={t("purchase_nfts.connect_to_wallet")} click={() => connectToWallet()}>
							<Button label="COMING SOON"></Button>
							:
							<>
								{isCorrectWallet ?
									<>
										{soldNfts !== maxNFTendos &&
											<Button label={sliderValue > 1 ? "PURCHASE " + sliderValue + " NFTS" : "PURCHASE " + sliderValue + " NFT"} click={() => purchaseNft()}></Button>
										}
									</>
									:
									!isCorrectWallet &&
									<Button label={t("purchase_nfts.wrong_chain_id")} click={() => connectToWallet()}></Button>
								}
							</>
						}
					</div>
				</div>
			</div>
		</div>
	)
});

export default PurchaseNfts;
