
import { action, makeObservable, observable } from "mobx";
import NotificationTypeEnum from "../enums/notification-type-enum";
import ScrollLinkEnum from "../enums/scroll-link-enum";

export class UiStore {
    theme = localStorage.getItem("theme");
    background = "";
    isLoading = false;
    showNotification = true;
    hamburgerMenuOpen = false;
    homeMounted = false;
    buyNftClicked = false;
    scrollLink = ScrollLinkEnum.HOME;
    isFooterVisible = false;
    languageFlag = "";
    metadata = [];
    searchNftendo = "";
    metadataSearchResult = [];
    showMyNfts = false;
    notificationBar = {
        isVisible: false,
        type: NotificationTypeEnum.SUCCESS,
        message: ""
    }
    isCorrectWallet = true;
    modalOpen = false;

    constructor() {
        makeObservable(this, {
            //observable state
            theme: observable,
            background: observable,
            isLoading: observable,
            languageFlag: observable,
            hamburgerMenuOpen: observable,
            homeMounted: observable,
            buyNftClicked: observable,
            scrollLink: observable,
            isFooterVisible: observable,
            metadata: observable,
            searchNftendo: observable,
            metadataSearchResult: observable,
            showMyNfts: observable,
            notificationBar: observable,
            isCorrectWallet: observable,
            modalOpen: observable,

            //actions
            setTheme: action,
            setBackground: action,
            setIsLoading: action,
            setLanguageFlag: action,
            switchTheme: action,
            switchHamburgerMenu: action,
            setHomeMounted: action,
            setBuyNftClicked: action,
            setScrollLink: action,
            setIsFooterVisible: action,
            setMetadata: action,
            setSearchNftendo: action,
            setMetadataSearchResult: action,
            setShowMyNfts: action,
            setNotificationBarVisibility: action,
            setNotificationMessage: action,
            setModalOpen: action,
        });
    }

    setTheme = (theme) => {
        this.theme = theme;
    }

    setBackground = (background) => {
        this.background = background;
    }

    setIsLoading = (value) => {
        this.isLoading = value;
    }

    setLanguageFlag = (value) => {
        this.languageFlag = value;
    }

    switchTheme = () => {
        if (this.theme === "light") {
            this.theme = "dark";
            localStorage.setItem("theme", "dark");
        } else {
            this.theme = "light";
            localStorage.setItem("theme", "light");
        }
    }

    switchHamburgerMenu = () => {
        this.hamburgerMenuOpen = !this.hamburgerMenuOpen;
    }

    setHomeMounted = (value) => {
        this.homeMounted = value;
    }

    setBuyNftClicked = (value) => {
        this.buyNftClicked = value;
    }

    setScrollLink = (value) => {
        this.scrollLink = value;
    }

    setIsFooterVisible = (value) => {
        this.isFooterVisible = value;
    }

    setMetadata = (value) => {
        this.metadata = value;
    }

    setSearchNftendo = (value) => {
        this.searchNftendo = value;
    }

    setMetadataSearchResult = (value) => {
        this.metadataSearchResult = value;
    }

    setShowMyNfts = (value) => {
        this.showMyNfts = value;
    }

    setNotificationMessage = (message, type) => {
        this.notificationBar.type = type;
        this.notificationBar.message = message;
        this.setNotificationBarVisibility(true);
    }

    setNotificationBarVisibility = (value) => {
        this.notificationBar.isVisible = value;
    }

    setModalOpen = (value) => {
        this.modalOpen = value;
    }
}

export default UiStore;