import { observer } from "mobx-react";
import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import coreStyles from 'react-awesome-slider/src/core/styles.scss';
import animationStyles from 'react-awesome-slider/src/styled/cube-animation/cube-animation.scss';
import styles from "./index.module.scss";
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AwsomeSliderFdm = observer(() => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    return (
        <AutoplaySlider className={styles["slider"]}
            mobileTouch={false}
            animation="cubeAnimation"
            cssModule={[coreStyles, animationStyles, styles]}
            play={true}
            cancelOnInteraction={true}
            interval={3000}
            bullets={false}
        >
            <div data-src="images/1.png" />
            <div data-src="images/2.png" />
            <div data-src="images/3.png" />
            <div data-src="images/4.png" />
            <div data-src="images/5.png" />
            <div data-src="images/6.png" />
            <div data-src="images/7.png" />
            <div data-src="images/8.png" />
            <div data-src="images/9.png" />
            <div data-src="images/10.png" />
        </AutoplaySlider>
    )
});

export default AwsomeSliderFdm;
