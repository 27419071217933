export const LightIcon = (props) => {
    let color = "";
    if (props.theme === "light") {
        color = "white";
    } else {
        color = "grey"
    }
    return (
        <svg viewBox="0 0 64 64" color="secondaryDark" fill={color} width="18px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBwQ cBxJlT">
            <circle data-name="layer2" cx="32" cy="32" r="15"></circle>
            <path data-name="layer1" d="M32 12a2 2 0 0 0 2-2V2a2 2 0 0 0-4 0v8a2 2 0 0 0 2 2zm0 40a2 2 0 0 0-2 2v8a2 2 0 0 0 4 0v-8a2 2 0 0 0-2-2zm30-22h-8a2 2 0 1 0 0 4h8a2 2 0 0 0 0-4zm-50 2a2 2 0 0 0-2-2H2a2 2 0 0 0 0 4h8a2 2 0 0 0 2-2zm3-14.1a2 2 0 1 0 2.9-2.9l-5.7-5.6a2 2 0 1 0-2.8 2.8zm34 28.2a2 2 0 0 0-2.9 2.9l5.7 5.7a2 2 0 1 0 2.8-2.8zm-1.4-27.7a2 2 0 0 0 1.4-.6l5.7-5.7a2 2 0 1 0-2.8-2.8L46.1 15a2 2 0 0 0 1.4 3.4zM15 46.1l-5.6 5.7a2 2 0 1 0 2.8 2.8l5.7-5.6a2 2 0 0 0-2.9-2.9z"></path>
        </svg>
    )
}
