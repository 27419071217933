import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Button = observer((props) => {
    return (
        <div onClick={props.click} className={styles["button-container"]}>
            <div className={styles["button"]}>
                {props.label}
            </div>
        </div>
    )
});

export default Button;