import { observer } from "mobx-react";
import React from 'react';
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Socials = observer(() => {

    return (
        <div className={styles["socials-container"]}>
                <a href="">
                    <img alt="Telegram icon" className={styles["social-icons"]} src={"images/telegram.svg"} />
                </a>
                <a href="">
                    <img alt="Discord icon" className={styles["social-icons"]} src={"images/discord.svg"} />
                </a>
                <a href="">
                    <img alt="Twitter icon" className={styles["social-icons"]} src={"images/twitter.svg"} />
                </a>
        </div>
    )
});

export default Socials;