import { ethers } from "ethers";
import { action, makeObservable, observable } from "mobx";
import Web3Modal from "web3modal";
import configData from "../constants/config-data.json"
import NotificationTypeEnum from "../enums/notification-type-enum";
const erc20Abi = require("erc-20-abi");

export class EtherStore {
  walletAddress = "";
  walletBalance = 0;
  chainId = 0;
  nftBalance = [];
  signer = {};
  sliderValue = 1;
  soldNfts = 0;
  maxNFTendos = 10000;
  currentPrice = 0;
  address = configData.address;
  acceptedTokenAddress = configData.acceptedTokenAddress;
  PROJECT_ID = configData.projectId;
  defaultProvider = new ethers.getDefaultProvider(configData.defaultProvider);
  errorMessage = {};
  abi = [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "maxSupply",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "mintPrice",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "randomiserAddress_",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "acceptedTokenAddress_",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "approved",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "ApprovalForAll",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "BURN_ADDRESS",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "MAX_SUPPLY",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "MINT_PRICE",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "PROVENANCE_HASH",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "acceptedToken",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "acceptedTokenAddress",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "defaultTokenURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getApproved",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getRandomTokenIdOffset",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "isApprovedForAll",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "isRevealed",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "maxNFTsPerMint",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "numNFTs",
          "type": "uint256"
        }
      ],
      "name": "mintCard",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "ownerOf",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "pauseSale",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "randomiser",
      "outputs": [
        {
          "internalType": "contract Randomiser",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "randomiserAddress",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "randomness",
          "type": "uint256"
        }
      ],
      "name": "receiveRandomness",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "saleIsActive",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "baseURI",
          "type": "string"
        }
      ],
      "name": "setBaseURI",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "defaultTokenURI_",
          "type": "string"
        }
      ],
      "name": "setDefaultTokenURI",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "numNFTs",
          "type": "uint256"
        }
      ],
      "name": "setMaxNFTsPerMint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "tokenDirURI_",
          "type": "string"
        }
      ],
      "name": "setTokenDirURI",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "startSale",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes4",
          "name": "interfaceId",
          "type": "bytes4"
        }
      ],
      "name": "supportsInterface",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "tokenByIndex",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "tokenDirURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "tokenIdOffset",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "tokenOfOwnerByIndex",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "tokenURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "tokenContract",
          "type": "address"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];
  defaultContract = new ethers.Contract(this.address, this.abi, this.defaultProvider);
  uiStoreRef = null;

  constructor(rootStore) {
    makeObservable(this, {
      //observable state
      walletAddress: observable,
      signer: observable,
      sliderValue: observable,
      walletBalance: observable,
      nftBalance: observable,
      soldNfts: observable,
      maxNFTendos: observable,
      currentPrice: observable,
      chainId: observable,

      //actions
      setWalletAddress: action,
      setWalletBalance: action,
      setNftBalance: action,
      connectToWallet: action,
      purchaseNft: action,
      setSigner: action,
      setSliderValue: action,
      setSoldNfts: action,
      setMaxNFTendos: action,
      setCurrentPrice: action,
      setChainId: action,
    });
    this.uiStoreRef = rootStore.uiStore;
    this.getTotalSold();
    this.getCurrentPrice();
    this.getMaxNFTendos();
  }

  getTotalSold = async () => {
    try {
      const amountSold = await this.defaultContract.totalSupply();
      this.setSoldNfts(String(amountSold));
    } catch (e) {
      console.log(e);
    }
  };

  getMaxNFTendos = async () => {
    try {
      const maxAmount = await this.defaultContract.MAX_NFTENDOS();
      this.setMaxNFTendos(String(maxAmount));
    } catch (e) {
      console.log(e);
    }
  };

  getNftBalance = async () => {
    try {
      const nftsOwned = await this.defaultContract.tokensOfOwner(this.walletAddress);
      this.setNftBalance(nftsOwned);
    } catch (e) {
      console.log(e);
    }
  };

  getCurrentPrice = async () => {
    try {
      const price = await this.defaultContract.MINT_PRICE();
      this.setCurrentPrice(String(price));
    } catch (e) {
      console.log(e);
    }
  };

  connectToWallet = async () => {
    let provider;

    const providerOptions = {
    };

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions // required
    });
    try {
      const connector = await web3Modal.connect();
      provider = new ethers.providers.Web3Provider(connector);
      this.setSigner(provider.getSigner());
      this.setWalletAddress(provider.provider.selectedAddress);
      const acceptedTokenContract = new ethers.Contract(this.acceptedTokenAddress, erc20Abi, this.signer);
      const balance = await acceptedTokenContract.balanceOf(this.walletAddress);
      this.setWalletBalance(ethers.utils.formatEther(balance));
      this.getNftBalance();
      this.setChainId(parseInt(provider.network.chainId));
      if (parseInt(provider.network.chainId) !== configData.chainId) {
        this.uiStoreRef.isCorrectWallet = false;
        this.showNotification(
          !this.uiStoreRef.notificationBar.isVisible,
          "Wrong Chain ID - connect to Polygon!",
          NotificationTypeEnum.ERROR
        )
      } else {
        this.uiStoreRef.isCorrectWallet = true;
      }

      connector.on("accountsChanged", (accounts) => {
        this.setWalletBalance(ethers.utils.formatEther(balance));
      });

      // Subscribe to chainId change
      connector.on("chainChanged", (chainId) => {
        this.setChainId(parseInt(chainId));
        if (parseInt(chainId) !== configData.chainId) {
          this.showNotification(
            !this.uiStoreRef.notificationBar.isVisible,
            "Wrong Chain ID, connect to Binance Smart Chain Testnet!",
            NotificationTypeEnum.ERROR
          )
        }
      });

      // Subscribe to provider connection
      connector.on("connect", (info) => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      connector.on("disconnect", (error) => {
        console.log(error);
      });

    } catch (e) {
      console.log(e);
    }
  };

  showNotification = (condition, notificationMessage, notificationType) => {
    if (this.uiStoreRef) {
      if (condition) {
        this.uiStoreRef.setNotificationMessage(
          notificationMessage,
          notificationType
        )
      } else {
        const resetValue = notificationType === NotificationTypeEnum.ERROR
        this.uiStoreRef.setNotificationBarVisibility(resetValue)
        this.uiStoreRef.setNotificationMessage(
          notificationMessage,
          notificationType
        )
      }
    }
  }

  setSigner = (value) => {
    this.signer = value;
  }

  setWalletAddress = (value) => {
    this.walletAddress = value;
    this.showNotification(
      !this.uiStoreRef.notificationBar.isVisible,
      "Successfully connected to wallet: ..." + value.slice(-8),
      NotificationTypeEnum.SUCCESS
    )
  }

  setWalletBalance = (value) => {
    this.walletBalance = value;
  }

  setChainId = (value) => {
    this.chainId = value;
  }

  setNftBalance = (value) => {
    this.nftBalance = value;
  }

  setCurrentPrice = (value) => {
    this.currentPrice = value;
  }

  setSoldNfts = (value) => {
    this.soldNfts = value;
  }

  setMaxNFTendos = (value) => {
    this.maxNFTendos = value;
  }

  purchaseNft = async () => {
    const acceptedTokenContract = new ethers.Contract(this.acceptedTokenAddress, erc20Abi, this.signer);
    const contract = new ethers.Contract(this.address, this.abi, this.signer);
    const purchasePrice = ethers.BigNumber.from(this.sliderValue).mul(this.currentPrice);
    let totalNftPrice = ethers.utils.parseEther(purchasePrice.toString());
    let successfulPurchase = false;
    try {
      let tx;
      const allowance = await acceptedTokenContract.allowance(this.walletAddress, contract.address);
      if (allowance.lt(totalNftPrice)) {
        // Increase allowance for this ERC-20, otherwise mintCard will fail
        tx = await acceptedTokenContract.approve(contract.address, totalNftPrice);
        await tx.wait(10);
      }
      tx = await contract.functions.mintCard(this.sliderValue);
      await tx.wait();
      successfulPurchase = true;
      this.getTotalSold();
      this.getCurrentPrice();
      const balance = await this.defaultProvider.getBalance(this.walletAddress);
      this.setWalletBalance(ethers.utils.formatEther(balance));
    } catch (e) {
      this.errorMessage = e;
      console.log('Transaction failed');
    }
    if (successfulPurchase) {
      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Successfully purchased cards!",
        NotificationTypeEnum.SUCCESS
      )
    }
    else {
      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        this.errorMessage && this.errorMessage.data && this.errorMessage.data.message || 'Failed to purchase cards!',
        NotificationTypeEnum.ERROR
      )
    }
  };

  setSliderValue = (value) => {
    this.sliderValue = value;
  }
}

export default EtherStore;
