import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { DarkIcon } from "../../assets/img/darkIcon.js";
import { LightIcon } from "../../assets/img/lightIcon.js";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const NavigationBar = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { switchTheme, theme, hamburgerMenuOpen, switchHamburgerMenu, scrollLink, setScrollLink } = uiStore;

    return (
        <>
            <div className={styles["navbar-container"]}>
                <div className={styles["navbar"]}>
                    <div className={styles["logo-container"]}>
                        <NavLink to="/">
                            <img className={styles["logo"]} alt={"Sex Works Logo"} src={"images/sex-works-logo-dark.svg"} />
                        </NavLink >
                    </div>
                    <div className={styles["links-container"]}>
                        <div className={styles["links"]}>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.HOME)} exact={true} activeClassName={scrollLink === ScrollLinkEnum.HOME ? styles["active-link"] : null} to="/">{t("navbar.home")}</NavLink >
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.ABOUT)} activeClassName={scrollLink === ScrollLinkEnum.ABOUT ? styles["active-link"] : null} to="/#about">{t("navbar.about")}</NavLink>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.PURCHASE)} activeClassName={scrollLink === ScrollLinkEnum.PURCHASE ? styles["active-link"] : null} to="/#purchase">{t("navbar.purchase")}</NavLink>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.ROADMAP)} activeClassName={scrollLink === ScrollLinkEnum.ROADMAP ? styles["active-link"] : null} to="/#roadmap">{t("navbar.roadmap")}</NavLink>
                        </div>
                        <div className={styles["theme-wrapper"]}>
                            <div className={styles["hamburger-menu"]} onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); }}>
                                {t("navbar.menu")}
                            </div>
                        </div>
                    </div>
                    <div className={styles["socials-container"]}>
                        <div className={styles["dark-light-theme"]} onClick={() => { switchTheme(); }}>
                            <LightIcon theme={theme} /><DarkIcon theme={theme} />
                        </div>
                    </div>
                </div>
            </div>
            {hamburgerMenuOpen ?
                <div className={styles["hamburger-menu-dropdown"]}>
                    <div className={styles["responsive-links-container"]}>
                        <NavLink onClick={() => setScrollLink(ScrollLinkEnum.HOME)} exact={true} activeClassName={scrollLink === ScrollLinkEnum.HOME ? styles["active-link"] : null} to="/">{t("navbar.home")}</NavLink >
                        <NavLink onClick={() => setScrollLink(ScrollLinkEnum.ABOUT)} activeClassName={scrollLink === ScrollLinkEnum.ABOUT ? styles["active-link"] : null} to="/#about">{t("navbar.about")}</NavLink>
                        <NavLink onClick={() => setScrollLink(ScrollLinkEnum.PURCHASE)} activeClassName={scrollLink === ScrollLinkEnum.PURCHASE ? styles["active-link"] : null} to="/#purchase">{t("navbar.purchase")}</NavLink>
                        <NavLink onClick={() => setScrollLink(ScrollLinkEnum.ROADMAP)} activeClassName={scrollLink === ScrollLinkEnum.ROADMAP ? styles["active-link"] : null} to="/#roadmap">{t("navbar.roadmap")}</NavLink>
                        <div className={styles["social-mobile-container"]}>
                            <a href="">
                                <img alt="Telegram icon" className={styles["social-icons-mobile"]} src={"images/telegram.svg"} />
                            </a>
                            <a href="">
                                <img alt="Discord icon" className={styles["social-icons-mobile"]} src={"images/discord.svg"} />
                            </a>
                            <a href="">
                                <img alt="Twitter icon" className={styles["social-icons-mobile"]} src={"images/twitter.svg"} />
                            </a>
                        </div>
                        <div className={styles["dark-light-theme-mobile-wrapper"]}>
                            <div className={styles["dark-light-theme-mobile"]} onClick={() => { switchTheme(); }}>
                                <LightIcon theme={theme} /><DarkIcon theme={theme} />
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
});

export default NavigationBar;
