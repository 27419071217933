export const DarkIcon = (props) => {
    let color = "";
    if (props.theme === "dark") {
        color = "white"
    } else {
        color = "grey";
    }
    return (
        <svg viewBox="0 0 64 64" color="textDisabled" fill={color} width="18px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBwQ krugtI">
            <path data-name="layer1" d="M32 10a22 22 0 1 0 22 22 22 22 0 0 0-22-22zm0 40a17.9 17.9 0 0 1-5-.7 18 18 0 0 0 0-34.6A18 18 0 1 1 32 50z"></path>
        </svg>
    )
}
