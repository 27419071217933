export const TRANSLATIONS_EN = {
    navbar: {
        home: "HOME",
        about: "ABOUT",
        purchase: "PURCHASE",
        faq: "FAQ",
        roadmap: "ROADMAP",
        menu: "MENU",
    },
    header: {
        title1: "SEX WORKS",
        title2: "MOONRIVER MARKETPLACE",
        description1: "You have seen punks fly off the shelves, apes enjoying the yachting lifestyle and by now, it seems the copycats are busy rando-generating the entire animal kingdom.",
        description2: "We could have just joined in on this gold rush, but that is not how we roll here in the Sex Works fam. Instead, we decided to do what we do best: create it first, then burn it all down to the ground in a glorious inferno!",
        tv_text: ">>> CLICK ME <<<"
    },
    purchaseNfts: {
        title: "PURCHASE AFK COLLECTION",
        sold: "SOLD",
        balance: "Balance: ",
        connect_to_wallet: "CONNECT TO WALLET",
        purchase_nftendos: "PURCHASE NFTENDOS",
        wrong_chain_id: "CONNECTED TO WRONG CHAIN",
        accept_tos: "YOU MUST ACCEPT OUR TOS",
        disclaimer: "By purchasing Sex Works you agree that you are only purchasing an NFT. Any potential community rewards are dependant on the total supply of Sex Works having been sold out within 6 months from the start of sale. In the event that the total supply of Sex Works have not been sold out within 6 months from start of sale, no community rewards will be given out."
    },

    aboutKites: {
        title: "KITES OF FREEDOM",
        description1: "Before the Taliban came to power there was one particular activity that was near and dear to the hearts of the Afghani people. That was Kite Flying. It was a touchstone of every Afghani soul. In 1996 as soon as the Taliban took power, they banned kite flying. This was a devastating blow to the bedrock of Afghani culture. Now everything was under the strict Sharia Law. Even dancing was prohibited.",
        description2: "Now with the take over of Afghanistan. So many thoughts are turned to the people. What will become of them? What will happen now that the Taliban has taken over. How can we help?",
        description3: "This is the cause that the team rallied around to kick off this initiative. We have watched the headlines and have been sick over the tragedies taking place. We are currently engaged in several talks with NPOs and foundations that are helping the Afghani people in this great time of need. Please send us a proposal of which organizations you feel we should fund. We are looking for a partner that can be fully vetted and is impactful.",
    },
    rangeSlider: {
        title: "NFTs to purchase",
        total: "Total : "
    },
    roadmap: {
        title: "ROADMAP",
        blocks: [
            { title: "FUND token Distribution", description: "Distribute fungible FUND tokens to genesis KITE of Freedom NFT holders. Fund tokens will serve as a voting mechanism in our DAO for further series." },
            { title: "Networking", description: "Partner up and collaborate with various organizations to broaden the network and influence range." },
            { title: "DAO", description: "Set up the DAO and begin voting decisions on the choice of further series proceeds. (Choosing a theme, deciding what to do with the funds)." },
            { title: "New series", description: "Design and mint new series." },
        ]
    },
    faq: {
        title: "FREQUENTLY ASKED QUESTIONS",
        items: [
            { question: "Is the community reward system fully randomized?", answer: "Yes. We have worked with Chainlink and their VRF solution which provides tamper-proof randomness that cannot be manipulated by any user, node operator, or malicious actor compared to for example using block.time which could be manipulated: https://chain.link/solutions/chainlink-vrf" },
            { question: "What is the provance record for NFTendo?", answer: "Each NFTendo has been hashed using SHA-256, and all hashes are then combined into one long string which is again hashed using SHA-256 and stored on the blockchain inside the NFTendo smart contract." },
            { question: "Will the value of my NFTendos increase?", answer: "NFTendos are NFTs designed as collectible trading cards on the Binance Smart Chain. Just as any other piece of art, the price fully depends what people are willing to pay, and the cards you value the highest may for others have zero value. Just as buying any piece of art, you can't know if the price will stay stable, increase of have a value of zero." },
            { question: "Can I, in advance, know which NFTendo I will get?", answer: "Before a NFTendo has been minted, all information except for the SHA-256 hash belonging to the image is unrevealed. After a purchase has been made, the NFTendo repository is automatically updated with the corresponding image and stats with a few minutes." },
            { question: "What happens if not all 10000 NFTendos have been sold within 6 months?", answer: "In the event that not all NFTendos have been sold within 6 months, the community reward is cancelled and all funds collected goes to the team." },
            { question: "Will NFTendo not get anything if all 10000 NFTendos have been sold within 6 months?", answer: "Once the community reward pool (2000 BNB) has been filled up, NFTendo can withdraw any excess funds. As an example, if there is 2100 BNB on the contract then NFTendo are able to withdraw a maximum of 100 BNB during the first 6 months. If there is 1900 BNB, NFTendo cannot withdraw any funds from the contract during the first 6 months. Should not all 10000 NFTendos have been sold within 6 months, then the community reward system has been unsuccessful which means no community rewards are paid out and NFTendo can withdraw all funds currently in the contract." },
            { question: "How do I interact with my NFTendos?", answer: "You can view your NFTendos on this website and on any wallet with support, and they are tradable on open NFT Marketplaces supporting the BEP721 standard." },
            { question: "I won! How do I claim my community reward?", answer: "You can claim your community reward on the website within 6 months from start of sale, if the community reward program was successful. To collect your community reward, input the slot number and press the Claim Reward button. To successfully claim, the winner address and the address you are transacting from must match." },
            { question: "What happens if there are unclaimed community rewards after six months from start of sale?", answer: "Any unclaimed funds left on the contract 6 months from the start of sale will also belong to the team." },
            { question: "What is the code?", answer: "Up, Up, Down, Down, Left, Right, Left, Right, B, A." }
        ]
    },
};
